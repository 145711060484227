var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0"}},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"green","dark":""}},[_c('v-tabs-slider',{attrs:{"color":"#086318"}}),_c('v-tab',[_vm._v("CAPTAÇÃO")]),_c('v-tab-item',[_c('v-card-text',[_c('v-form',{ref:"searchDonor",on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('p',{staticClass:"space-personalized"}),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":""}},[_c('v-text-field',{attrs:{"color":"#086318","label":"Nome","placeholder":"Nome completo","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"1","color":"blue"},on:{"click":function($event){return _vm.search()}}},[_c('span',{staticClass:"textBtn"},[_vm._v(" Buscar ")])]),_c('div',{staticClass:"space"}),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"1","color":"grey"},on:{"click":function($event){return _vm.clear()}}},[_c('span',{staticClass:"textBtn"},[_vm._v(" Limpar ")])]),_c('div',{staticClass:"space"}),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"1","color":"green"},on:{"click":function($event){return _vm.$router.push('/cadastrar-captacao')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#FFFFFF"}},[_vm._v("mdi-shape-square-rounded-plus")]),_c('span',{staticClass:"textBtn"},[_vm._v(" Nova Captação ")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.donorCaptations,"footer-props":{'items-per-page-text': 'Doador por Página',},"page":_vm.page,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('TagTipoCaptacao',{attrs:{"resource":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"22","color":"green","align-item":"center"},on:{"click":function($event){return _vm.redirect(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-lead-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":"","color":"#474545"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"22","color":"#474545","align-item":"center"},on:{"click":function($event){return _vm.$router.push({path: '/relatorio-captacao', query:{item:item}})}}},'v-icon',attrs,false),on),[_vm._v("mdi-printer ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Imprimir Relatório")])])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }