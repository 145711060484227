<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab>CAPTAÇÃO</v-tab>
 
            <v-tab-item>
              <v-card-text>
                <v-form @submit.prevent="search()" ref="searchDonor">
                  <p class="space-personalized"></p>
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="">
                        <v-text-field
                        color="#086318" 
                        label="Nome" 
                        placeholder="Nome completo" 
                        outlined 
                        v-model="name"
                        dense></v-text-field>
                      </v-col>

                      <v-btn class="rounded-lg" elevation="1" color="blue" @click="search()">
                          <span class="textBtn"> Buscar </span>
                      </v-btn> 

                      <div class="space"></div>

                      <v-btn class="rounded-lg" elevation="1" color="grey" @click="clear()">
                          <span class="textBtn"> Limpar </span>
                      </v-btn>

                      <div class="space"></div>

                      <v-btn class="rounded-lg" elevation="1" color="green" @click="$router.push('/cadastrar-captacao')">
                        <v-icon class="mr-2" color="#FFFFFF">mdi-shape-square-rounded-plus</v-icon>
                        <span class="textBtn"> Nova Captação </span>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
  
              <!-- Início da Tabela -->
              <v-data-table
                :headers="headers"
                :items="donorCaptations"
                :footer-props="{'items-per-page-text': 'Doador por Página',}"
                :page="page"
                :loading="loading">

                <template v-slot:[`item.value`]="{ item }">
                  <TagTipoCaptacao :resource="item"/>
                </template>
        
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="icon"
                        color="green" 
                        v-on="on"
                        v-bind="attrs"
                        align-item="center"
                        @click="redirect(item)">mdi-lead-pencil 
                      </v-icon>
                    </template>
                      <span style="color:white">Editar</span>
                    </v-tooltip>
                  <v-tooltip right color="#474545">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="icon"
                        color="#474545" 
                        v-on="on"
                        v-bind="attrs"
                        align-item="center"
                        @click="$router.push({path: '/relatorio-captacao', query:{item:item}})">mdi-printer
                      </v-icon>
                    </template>
                      <span style="color:white">Imprimir Relatório</span>
                    </v-tooltip>
                </template>
                
              </v-data-table>
           </v-tab-item>
      </v-tabs>
  </v-card>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import TagTipoCaptacao from "./TagTipoCaptacao-comp.vue";
  
  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Doador",
            align: "center",
            value: "name",
          },

          {
            text: "Tipo de Doador",
            align: "center",
            value: "value",
          },

          { text: "Ações", value: "actions", sortable: false,  align: "center" },
        ],

        displayError: {
          display: false,
          message: [],
        },
        page: 1,
        numberOfPages: 0,
        loading: true,
        options: {},
        sortBy: "name",
        donorCaptations: [],
        message: "",
        name: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
      };
    },
    
    components: {
      TagTipoCaptacao
    },

    created() {
      this.loadTable();
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
      
      loadTable(){
        axios.get("/donor/allCaptations").then((response) => {
          if(this.$store.state.profile_id == 1){
            this.donorCaptations = response.data.data;
            let captation = this.donorCaptations.filter((x)=> x.captation != null); //mudar isso aqui depois, levar pro back
            let pcr_donor_data = this.donorCaptations.filter((x)=> x.pcr_donor_data != null);
            this.donorCaptations = captation.concat(pcr_donor_data);
            this.loading = false;
          }else{
            this.donorCaptations = response.data.data;
            this.donorCaptations = this.donorCaptations.filter((x)=> x.unity_id == this.$store.state.unity_id);
            let captation = this.donorCaptations.filter((x)=> x.captation != null); //mudar isso aqui depois, levar pro back
            let pcr_donor_data = this.donorCaptations.filter((x)=> x.pcr_donor_data != null);
            this.donorCaptations = captation.concat(pcr_donor_data);
            this.loading = false;
          }
        });
      },

      redirect(item){
        if(item.captation){
          this.$router.push({path: '/editar-captacao', query:{item:item}})
        }else{
          this.$router.push({path: '/captacao-pcr', query:{item:item}})
        }
      },

      clear(){
        this.name = ""
        this.loading = true;
        this.loadTable();
      },

      search() {
      const donor = new Object();
        donor.name = this.name;
          axios.post("/captation/filter", donor).then((response) => {
            this.loading = true;
            this.donorCaptations = response.data.data; 
            this.loading = false;
        });
      },
      
    },
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  