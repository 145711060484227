<template>
    <v-chip
      :color="setStatus.bgColor"
      :text-color="setStatus.textColor"
    >
      <span class="status__title">
        {{ setStatus.title }}
      </span>
    </v-chip>
  </template>

  <script>
  export default {
    props: {
      resource: {
        type: Object,
      },
    },
  
    computed: {
      setStatus() {

          return this.resource.pcr_donor_data != null
          ? { bgColor: "#62fc84", textColor: "#70dba9", title: "Doador PCR" }
          : { bgColor: "#a2fcc9", textColor: "#FFFFFF", title: "Doador ME" };
    
      },
    },
  };
  </script>
  
  <style lang="scss">
  .status__title {
    font-weight: 600;
  }
  </style>